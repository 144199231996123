import './b.css';
import { useState,useEffect } from "react";




function Body() {
  const [greet, setGreet] = useState("GoodMorning");
  const [gree, setGree] = useState("👋 Hi ,there!!");
  useEffect(() => {
  
var d = new Date();
var curr_hour = d.getHours();
if(curr_hour>1 && curr_hour<12){
  setGreet("GoodMorning");
}
else if(curr_hour=>12){
  setGreet("Good Afternoon");
}
else if(curr_hour>18 && curr_hour <20){
  setGreet("Good evening");
}
else if(curr_hour>20 && curr_hour <23){
  setGreet("Good night");
}


}, []);

  return (
        <div className="body">
          <div className="space"></div>
      <div className="body-box">
              <div  className="body-txt">
       <p>{gree} </p>  
       <a> {greet}</a>
      </div>    
       <div  className="image">   
       <img src="Rohan.png" alt="Rohan Tajpuriya" />;
       </div>    
         </div>    
          <div className="body-txt2">
            <h2 data-aos="fade-right" data-aos-duration="1000" >UI & UX Designer</h2>
       <div className="txt2" data-aos="fade-right" data-aos-duration="2000" data-aos-delay="50">
        My name is  ROHAN Tajpuriya . <br/>I am from Salmara. <br/> Shivashaktshi Ward No.4 ,Jhapa</div>
        
        

        </div>    
                <div  className="Ramdom-txt"> i love to do programming ,Research<br/> I am intrested in Web Development<br/> <div className="sp"></div>software Development.</div>
                <div className="h-t-1"> 🧰 / Student / Still learning</div>
      </div>
  );
}

export default Body;
