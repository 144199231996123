import logo from './logo.svg';
import { useState,useEffect } from "react";
import './App.css';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import GitHubIcon from '@mui/icons-material/GitHub';
import ReactGA from "react-ga4";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Body from './Component/Body.js';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

function App() {
  const [greet, setGreet] = useState("have a good day ");
  const [isOpen, setIsOpen] = useState(false)
  
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  
ReactGA.initialize("G-K0KTT3YCYN");
ReactGA.send({ hitType: "pageview", page: "/", title: "viwed" });
  
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    const Home = useRef(null);
    const about = useRef(null);
    const contact = useRef (null);
    const project = useRef (null);
const scrollToSection = (elementRef) => {
  setIsOpen(false);
window.scrollTo({
top: elementRef.current.offsetTop,
behavior: "smooth",
});
};
const form = useRef();
const sendEmail = (e) => {
    e.preventDefault();
if (!input1 || !input2 || !input3) {
      toast.error('Please Fill all the form !', {
         position: "top-center",
        autoClose: 1200,
         hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
         progress: undefined,
         theme: "colored",
         
        });
    } else {
      emailjs
      .sendForm('service_p0or6bq', 'template_cftjo42', form.current, {
        publicKey: 'rNSM2JP3vOmvi38qy',
      })
      .then(
        () => {
          toast.success('email sent Successfully!', {
position: "top-center",
autoClose: 1200,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: false,
progress: undefined,
theme: "colored",

});
setInput1("");
setInput2("");
setInput3("");
        },
        (error) => {
          toast.error('oops there is a problem!', {
         position: "top-center",
        autoClose: 1200,
         hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
         progress: undefined,
         theme: "colored",
         
        });
        },
      );
  };
    }
    
  
  useEffect(() => {
  



}, []);



  return (
    <div className="App">
      <ToastContainer />
      <Drawer style={{background: "linear-gradient(180deg, rgba(13,25,41,1) 22%, rgba(111,108,108,0.8048650301419772) 100%)"}}
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className='bla bla bla'
            >
        
          <div className="dr-logi">
            <div className="dr-logi-txt">
                ROHAN TAJ
             </div>
          </div>
          
           <div className="dr-menu">
                <div className="dr-menu-txt">
                   MENU
                 </div>
                      <div data-aos="fade-right" data-aos-duration="1500" onClick={() => scrollToSection (Home)} className="dr-menu-list">
                   <HomeOutlinedIcon htmlColor="#dae0e6" sx={{ fontSize: 28 ,ml:2}}/>
                                     <div className="dr-about" >Home</div>
                 </div>
                                  <div data-aos="fade-right" data-aos-duration="1500" onClick={() => scrollToSection (project)} className="dr-menu-list">
                   <AccountBalanceWalletOutlinedIcon htmlColor="#dae0e6" sx={{ fontSize: 28 ,ml:2 }}/>
                             <div className="dr-contact">Project</div>
                 </div>          
                 <div data-aos="fade-right" data-aos-duration="1500" onClick={() => scrollToSection (about)} className="dr-menu-list">
                   <PeopleAltOutlinedIcon htmlColor="#dae0e6" sx={{ fontSize: 28 ,ml:2}}/>
                                     <div className="dr-about" >About</div>
                 </div>
                 <div data-aos="fade-right" data-aos-duration="1500" onClick={() => scrollToSection (contact)} className="dr-menu-list">
                   <ContactsOutlinedIcon htmlColor="#dae0e6" sx={{ fontSize: 28 ,ml:2 }}/>
                             <div className="dr-contact">Contact</div>
                 </div>
       
          </div>
         <div className="dr-list">
            <div className="dr-list-heading">
                 
              </div>
               <div className="dr-list-items">
           
                </div>
          </div>


            </Drawer>
          
      <header ref={Home} className="App-header">
        <div onClick={toggleDrawer} className="head-icon">
          
          <DragHandleOutlinedIcon htmlColor="#dae0e6" sx={{ fontSize: 30 }}/>
        </div>
       <div className="head-txt">
           ROHAN TAJPURIYA
        </div>
        <a href="https://github.com/rohantajpuriya" className="head-last">
                    <GitHubIcon htmlColor="#dae0e6" sx={{ fontSize: 30 }}/>
        </a>
      </header>
      <Body/>


       
       <div ref={project} className="project">
       
       <div className="txt-1"> PROJECT</div>
       
       <div className="pro-top" data-aos="fade-right" data-aos-duration="1500"> 
        <div className="pro-top-1"> 
       Edu App
       </div>
        <div className="pro-top-2"> 
       This App with beautiful design <br/>Contribute in education  <br/>reading for more amazing<br/> colorful content for<br/> developers, designers and<br/> creators!
       <br/>       <br/>
       <div className="opss">
View our entire collection <br/>of Education<br/>
Content , for grade +2
</div>
       </div>
       <div className="pro-top-3"> 
       VIEW
       </div>
       </div>
  

       
       </div>
               <div className="opnion">Tweet Opinion </div>
          <div className="opnion-box" data-aos="slide-right" data-aos-duration="1900">
             <div className="opnion-box-wh">
            <div className="dv-1">
            <div className="dv-1-1">
       <img className="imgg" src="S.png" alt="Rohan Tajpuriya" />;
             </div>
          <div className="dv-1-2">
            kale..<br/>
           <div className="idnm">@rohan</div>  
             </div>
            <div className="dv-1-3">
       <img className="imgg" src="Ins.png" alt="Rohan Tajpuriya" />
             </div>
             </div>
             
             
             <div className="dv-2">
               success is not key to<br/> happiness ,happiness is<br/> key to success ✌️
             </div>
            <div className="dv-3">
            August 4, 2024 at 1:42 <br/> PM
             </div>
             </div>
             </div>
             
       
    <div ref={about} className="about">
          <div className="about-head">
            ABOUT - Me
           </div>
    <div className="about-txt2"  data-aos="fade-left" data-aos-duration="1500">
            Obsessed  by tech 
           </div>
      <div className="about-txt3" data-aos="fade-right" data-aos-duration="1500">
            ⚡ I have studied core subjects like  <br/> Data Structures, DBMS, Networking,<br/> Security, etc. also completed various online courses <br/>Backend , Web , Mobile App Development, etc.
           </div>
           
         <div className="about-txt4">
            Hobbies
           </div>
           
         <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">⚽
         <div className="space-li"></div>Play  Football 
           </div>
           
                <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">🍺
         <div className="space-li"></div> Drinking Beer
           </div>
                    <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">🏔️
         <div className="space-li"></div> Visit new places
           </div>
                    <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">🌟
         <div className="space-li"></div> play chess
           </div>
                    <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">🎨
         <div className="space-li"></div> Drawing Picture
           </div>
             <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">📸
         <div className="space-li"></div> Taking Picture
           </div>
           
                    <div className="about-txt4">
            Favorite
           </div>
         <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">🍍
         <div className="space-li"></div>furite - Pineapple
           </div>
           
                <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">✨
         <div className="space-li"></div> color - Yellow
           </div>
                    <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">️🐕
         <div className="space-li"></div> pet - Dog
           </div>
                    <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">🌻
         <div className="space-li"></div> Flower - Sunflower
           </div>
                    <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">🍔
         <div className="space-li"></div> Food - Burger
           </div>
             <div data-aos="fade-right" data-aos-duration="1500" className="about-txt6">🏍️
         <div className="space-li"></div> Bike - Duke 390
           </div>
           

       </div>
             <div ref={contact} className="contact">
        <div className="txt-1"> CONTACT</div>
        
        
        
        
        <form ref={form} onSubmit={sendEmail}>
      <div className="name">
        <label>Name</label>
        <input
        className="name-i"
        placeholder="Rohan tazz"
          type="text"
          name="name"
          value={input1}
        onChange={(e) => setInput1(e.target.value)}
        />

      </div>
      <div className="email">
        <label>Email</label>
        <input
        placeholder="aaaa@gmail.com"
        className="email-i"
          type="email"
          name="email"
          value={input2}
        onChange={(e) => setInput2(e.target.value)}
        />

      </div>
            <div className="msg">
        <label>Message</label>
        <textarea
        className="msg-i"
        placeholder="hi, rohan . can we meet tomorrow!"
          type="text"
          name="message"
          multiline
      rows="30"
          value={input3}
        onChange={(e) => setInput3(e.target.value)}
        />

      </div>
      
      <button className="btn" type="submit">Submit</button>
    </form>
        
        
        

             
             
             
               <div className="social"> 
        Social media
           <div  className="social-box"> 
         <a data-aos-once="true" data-aos="fade-right" data-aos-easing="ease-in-out"
    data-aos-mirror="true" data-aos-duration="500" data-aos-delay="2000" href="https://www.instagram.com/tajpuriya_kta?igsh=dnNzbW5kNzA3ZGZ4" className="ins"> 
               <img className="imgg" src="Ins.png" alt="Rohan Tajpuriya" />
        </a>
         <a  data-aos-once="true" data-aos="fade-right" data-aos-duration="500" data-aos-delay="1500" href="https://www.facebook.com/profile.php?id=61562753622110" className="ins"> 
               <img className="imgg" src="F.png" alt="Rohan Tajpuriya" />
        </a>
         <a data-aos-once="true" data-aos="fade-right" data-aos-delay="800" data-aos-duration="400" className="ins"> 
               <img className="imgg" src="T.png" alt="Rohan Tajpuriya" />
        </a>
        </div>
        </div>
       </div>
                  <div className="foot">
         <div className="about-txt7">
         Created by Rohan tajpuriya from Nepal
           </div>
     <div onClick={() => scrollToSection (contact)} className="about-txt8">
                      📧 Contact </div>
                      
                      
          <div className="about-txt9">
               © 2024 Rohan Taj</div>
                      </div>
    </div>
  );
}

export default App;
